body{
  background-color: #fff;
  font-family: 'Helvetica', 'Helvetica Neue', sans-serif;
  font-weight: 500;
  color: #08192C;
  font-size: 14px !important;
  line-height: 1.5;
}

.p-component {
  font-family: 'Helvetica', 'Helvetica Neue', sans-serif;
  font-weight: 500;
}

.p-inputtext {
  font-family: 'Helvetica', 'Helvetica Neue', sans-serif;
}

/******Colors*****/

.goodBlue{
  color: #2f7db4;
}

.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.alert-panel{
  background-color: #ffebce!important;
}

.admin-background{
  border: 2px solid #00968826!important;
  background-color: #e4f3e5!important;
}

.webColor{
  color: #3b569c;
}

.fbColor{
  color: #2f7db4;
}
.fbBackgroundColor{
  background-color: #2f7db4;
  color:white;
}

.instaColor{
  color: #E1306C;
}
.instaBackgroundColor{
  background-color: #E1306C;
  color:white;
}

.messengerColor{
  color: #0084FF;
}
.messengerBackgroundColor{
  background-color: #E1306C;
  color:white;
}

.googleColor{
  color: #ea3527;
}
.googleBackgroundColor{
  background-color: #ea3527;
  color:white;
}

.youtubeColor{
  color: #ea3527;
}
.youtubeBackgroundColor{
  background-color: #ea3527;
  color:white;
}

/*******Logo******/
.otlogo{
  width: auto;
  cursor: pointer;
  height: 43px;
}
.caratPgLogo{
  width: auto;
  margin-left: 24px;
  cursor: pointer;
  height: 59px;
  margin-top: -7px;
}
.publicisLogo{
  width: auto;
  cursor: pointer;
  height: 55px;
}

/******Distances******/

.marginRightIcon{
  margin-right: 5px;
}

.submitSideButtons{
  width: 105px;
}

.panel-group {
  padding: 35px;
  margin-bottom: 0 !important;
}

.panel{
  background-repeat: no-repeat;
  background-color: #fff;
  background-size: cover;
  background-position: center center;
  border: 0 solid transparent;
  border-radius: 5px;
  color: inherit;
  display: block;
  margin-bottom: 1.57142857rem;
  position: relative;
}

label {
  font-weight: 400;
}

nav{
  margin-left: 240px;
}



.ui-treetable-tablewrapper thead {
  background-color: #0068FA;
}


#toggle_sidebar{
  margin-top: 35px;
  background-color: #0068FA;
  color: #f6f8fa;
  box-shadow: 1px 1px 1px #444;
  font-size: 30px;
  position: fixed;
  transition: 0.5s;
  padding: 10px;
  width: 50px;
  text-align: center;
  border-radius: 2px;
  top: 52px;
  cursor: pointer;
  z-index: 1;
}

#myMobileSidenav{
  padding: 0;
  background: url('./assets/imgs/sidebar_background.png');
  /*background-color: #00b3f114;*/
  /*background: linear-gradient(115deg,#006baa 0%,#0093c1 100%);*/
}

#close_mobile_sidenav_button{
  color: #fff;
  background-color: transparent;
  border:none;
  font-size: 20px;
  width: 100%;
  text-align: left;
}


@media screen and (max-width: 992px) {
  #toggle_sidebar {
    background-color: #0068FA;
    color: #f6f8fa;
    margin-top: 8px;
    font-size: 30px;
    position: fixed;
    transition: 0.5s;
    padding: 10px;
    width: 50px;
    height: 49px;
    text-align: center;
    border-radius: 2px;
    top: 0;
    cursor: pointer;
    z-index: 999;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }

  #myMobileSidenav{
    font-size: 12pt;
    margin-top:0;
    z-index: 1016;
  }
}

.navbarDefault{
  border-radius: 0;
  padding-top: 25px;
  height: 105px;
  background: #fff;
  border-bottom: 1px solid #e2e6e8;
}

.menuLabel {
  color: #0068FA;
  line-height: 1.1;
  margin: 0 10px 0 0;
  font-size: 15pt;
  font-weight: 300;
  display: none;
}


.navButtons{
  color: #0068FA !important;
  background-color: transparent !important;
  border: none !important;
  border-bottom: solid 1px rgba(255, 255, 255, 0);
  font-size: 13pt;
  cursor: pointer;
}

.navButtons:hover{
  color:#5198d6 !important;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-radius: 0;
}

.menu_control{
  color: #f6f8fa;
  font-size: 30px;
  white-space: nowrap;
  transition: 0.3s;
  position: absolute;
  right: 20px;
  top: 10px;
  margin-top:10px;
}

.menu_control:hover {
  color: white;
  cursor: pointer;
  padding-bottom: 4px;
  border-bottom: solid white 2px;
}


@media screen and (max-width: 992px){
  .menuLabel {
    color: white;
  }
  .fa-bell{
    color: white !important;
  }
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  min-width: 38px;
  font-weight: 900;
  line-height: 1;
  color: #2294d4;
  position: relative;
  text-align: center;
  background-color: #fff;
  border: none;
}

textarea{
  resize: none;
  /*border: solid 1px #d0d0d0;*/
  /*background-color: white;*/
}

.main_content {
  margin: 76px 0 0 260px;
  position: relative;
  background: #fff;
}

@media screen and (max-width: 992px) {
  .main_content{
    padding: 0 5px 0;
    margin: 107px 0 0;
  }
}

.panel-default>.panel-heading {
  border: none;
}

.labelFiltersHeaderPage{
  min-width: 150px;
}

/*--- dropdown comandi navbar ---*/
.dropdown_el{
  border: none !important;
  text-align: left;
  line-height: 2;
  margin-top: 10px;
  width: 100%;
}

.dropdown_el:hover{
  background-color: #cccccc;
}

/*--- dropdown menu--- */
.dropdown-menu{
  border-radius: 0;
  padding: 0;
  border:none;
}

.btn{
  transition: 0.2s;
  /*border-radius: 25px;*/
  border-radius: 5px;
}

/*--- Info button ---*/
.infoButton{
  font-size: 20px;
  cursor: pointer;
  color: #fff;
  transition: 0.5s;
}

.infoColor{
  color: #1f89ce;
}

.popover-content{
  color: #08192C;
}

/* Nasconde lo scroll del background quando i modali sono aperti*/
.ReactModal__Body--open {
  overflow-y: hidden;
}

#reportFilters{
  display: none;
}

#presetBar{
  display: block;
}

#toggle_sidebar:hover{
  color: #0056ce;
}

.shortView{
  display: block;
}

#close_mobile_sidenav_button:hover{
  color: white;
  background-color: transparent;
  border:none;
}

.sidebarElement:hover{
  background-color: #0056ce;
}

.shortInputs{
  width: 190px;
}

.modalRow{
  padding-bottom: 14px;
}

.shortRows {
  margin-bottom: 5px;
}

.mediumRows {
  margin-bottom: 15px;
}

.largeRows {
  margin-bottom: 28px;
}

.xlargeRows {
  margin-bottom: 40px;
}

.littleDropdown{
  font-size: 10px;
}

.generalsCampaign{
  /*color: #08192C;*/
  /*margin: 10px 15px;*/
}

.panelCampaignDefault{
  padding: 40px 50px;
  background-color: white;
}

.tableModalSty{
  max-height: 50em; padding: 20px 15px 20px 20px;
}

.tableHeaderContainer{
  padding:4px 10px;
  border-bottom: 0 none;
}

.tableIconSearch{
  margin:4px 4px 0 0;
  color: #0277b9;
}

.platformBackColor {
  background-color: #00b3f1;
}

.tableInputSearch{
  max-width: 450px;
  width: 90%;
}

.bBlue{
  color: #4c93af;
}

.navbar-fixed-top{
  z-index: 100;
}

.otp-input{
  width: 400px;
  font-size: 15pt;
  padding: 10px;
  border-radius: 4px;
  color: #6f6f6f;
}

.panel-body{
  font-size: 16px;
  padding: 0 0 15px;
}

.tableGenericHeader{
  background-color: #0068FA;
  padding: 10px;
  color: white;
  font-size: medium;
}

.hover-times:hover{
  color: #8a031aa9;
  cursor: pointer;
}

/*COLORI TESTI*/
.redColor{
  color: #ff4c6a;
}
.aquaColor{
  color: #00b3f1;
}
.blueColor{
  color: #0068FA;
}
.midBlueColor{
  color: #00437f;
}
.greenColor{
  color: #37bd6c;
}
.lightGreenColor{
  color: #00d855;
}
.yellowColor{
  color: #ffa100;
}
.purpleColor{
  color: #9a91ff;
}
.pinkColor{
  color: #d168ff;
}
.adminColor{
  color: #009688!important;
}
.adminTitle{
  color: #009688;
}
.greyColor{
  color: #aaa;
}
.lightGreyColor{
  color: #eceaed;
}
.darkGreyColor{
  color: #888888;
}
.blackColor{
  color: #08192C;
}

.bgAquaColorLight{
  background-color: #00b3f114;
}

/* SCROLLBAR */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #989898;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
  border-radius: 5px;
}

.blueScrollbar::-webkit-scrollbar-thumb {
  background-color: #0068FA;
}

.blueScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #1078FF;
}

.grayScrollbar::-webkit-scrollbar-thumb {
  background-color: #989898;
}

.grayScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

.lightGrayScrollbar::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
}

.lightGrayScrollbar::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
}

.darkGrayScrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
}

.darkGrayScrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #989898;
}

/*BOTTONE GENERICO OTP*/
.otp-btn, button{
  color: #08192C;
  text-transform: uppercase;
  letter-spacing: .4px;
  font-weight: 500;
  border: 2px solid transparent;
  -webkit-transition: none;
  transition: 0.3s;
  cursor: pointer;
  font-size: 15px;
  line-height: 1.25;
  border-radius: .375rem;
}

.otp-btn {
  padding: 5px 10px;
}

.otp-btn:disabled{
  cursor: not-allowed;
  opacity: 0.6;
}
button{
  padding: .75rem 1.5rem;
}

/*BOTTONE GENERICO TABELLE*/
.otp-btn-table{
  border: 2px solid #dadfe6;
  color: #2a2a2a;
  background-color: transparent;
  min-width: 120px;
  margin-right: 20px;
  margin-bottom: 5px;
}
.otp-btn-table:hover{
  color: #08192C;
  background-color: #dfe3ea;
  border-color: transparent;
}
.otp-btn-table:focus{
  color: #08192C;
}

/*BOTTONI COLONNA COMANDI*/
.btn-action, .btn-actionFlat{
  font-size: 14px;
  padding: 5px;
  height: 31px;
  margin: 1px;
}

.btn-action {
  width: 34px;
}

.btn-actionFlat{
  width: 31px;
  border: solid 1px;
  border-radius: 100px;
}

.btn-action:disabled, .btn-actionFlat:disabled{
  cursor: not-allowed;
  opacity: 0.6;
}

.disable-section{
  pointer-events:none;
  cursor: not-allowed;
  opacity: 0.6;
}

/*BOTTONI MINI PER LE MODIFICHE INLINE*/
.btn-mini{
  font-size: x-small!important;
  padding: 2px;
  height: 20px;
  width: 20px;
  border-radius: 20px;
}


/*BOTTONE STOP REPORT*/
.btn-stop-report{
  margin-top: 30px;
  margin-left: 10px;
}

/* BOTTONE REQUEST 4D KEY */
.request-4d-key{
  text-transform: none;
  background: none;
  color: #2196f3;
  text-decoration: underline;
}

/*COLORI BOTTONI*/
.default{
  background-color: #fff;
  border-color: #cecece;
  color: #2a2a2a;
}
.default:hover{
  color: #08192C;
  background-color: #dfe3ea;
  border-color: #dfe3ea;
}

.admin-btn{
  background-color: #e4f3e5;
  border-color: #0096886e;
  color: #009688;
}
.admin-btn:hover{
  background-color: #d1e8d3;
  color: #005a52;
}

.no-style{
  color: unset!important;
  text-decoration: none!important;
}

.success{
  background-color: #37bd6c;
  border-color: #37bd6c;
  color: #fff;
}

.success:hover{
  color: #fff;
  background-color: #5be190;
  border-color: transparent;
}

.successFlat{
  background-color: white;
  border-color: #37bd6c;
  color: #37bd6c;
}

.successFlat:hover{
  color: #fff;
  background-color: #5be190;
  border-color: transparent;
}

.successLight{
  background-color: #37bd6c3b;
  border-color: #37bd6c;
}

.primary{
  background-color: #0068FA;
  border-color: #0068FA;
  color: #fff;
}
.primary:hover{
  background-color: #5198d6;
  color: #fff;
  border-color: transparent;
}

.info{
  background-color: #0068FA;
  border-color: #0068FA;
  color: #fff;
}
.info:hover{
  color: #fff;
  background-color: #0056ce;
  border-color: transparent;
}

.infoFlat{
  background-color: white;
  border-color: #0068FA;
  color: #0068FA;
}
.infoFlat:hover{
  color: #fff;
  background-color: #0056ce;
  border-color: transparent;
}

.warning{
  background-color: #ffa100;
  border-color: #ffa100;
  color: #fff;
}
.warning:hover{
  color: #fff;
  background-color: #ffae24;
  border-color: transparent;
}

.warningLight{
  background-color: #ffa1002b;
  border-color: #ffa100;
}

.danger{
  background-color: #ff4c6a;
  border-color: #ff4c6a;
  color: #fff;
}
.danger:hover{
  color: #fff;
  background-color: #ff657f;
  border-color: transparent;
}
.dangerFlat{
  background-color: white;
  border-color: #ff4c6a;
  color: #ff4c6a;
}
.dangerFlat:hover{
  color: #fff;
  background-color: #ff657f;
  border-color: transparent;
}

.dangerLight{
  background-color: rgba(255, 76, 106, 0.08);
  border-color: #ff4c6a;
}

.secondary{
  background-color: #9a91ff;
  border-color: #9a91ff;
  color: #fff;
}
.secondary:hover{
  color: #fff;
  background-color: #a99fff;
  border-color: transparent;
}

.textarea-flat{
  width: 100%;
  max-width: 500px;
}

.copy-flat-button{
  text-transform: none;
  padding: 5px 10px;
  font-size: 11px!important;
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
  border-radius: 6px;
  background-color: #fff;
  color: #00b3f1;
}
.copy-flat-button:hover{
  color: #0068FA;
  background-color: #dfe3ea;
  border-color: #dfe3ea;
}


/*Flat style tables and buttons*/
.celWithBorderTopTableFlat{
  border-top: solid 1px #e9e6e6 !important;
}
.firstCelTableFlat{
  border-left: solid 1px #e9e6e6 !important;
}

.lastCelTableFlat{
  border-right: solid 1px #e9e6e6 !important;
}

.filtersModalFlat{
  padding: 13px 13px 10px 0;
  /*max-width: 656px;*/
  margin-right: 0;
  margin-left: auto;
  box-shadow: rgba(0,0,0,.2) 0 2px 5px 0!important;
  position: absolute;
  z-index: 9;
  background-color: white;
  right: 0;
}


.titleWithMaxWidthFlat{
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
.infoWithMaxWidthFlat{
  position: relative;
  left: 5px;
  bottom: 5px;
}

.inline-block{
  display: inline-block;
}

.buttonsHeaderFlat, .filtersHeaderFlat{
  box-shadow: rgba(0,0,0,.2) 0 2px 5px 0!important;
  margin-right: 1px;
  margin-left: 1px;
  position: relative;
}

.buttonsHeaderFlat {
  padding: 5px;
}

.filterBarFlat{
  margin: 0;
  border-bottom: solid 1px #e9e6e6;
}


.buttonFlat{
  background-color: white;
  padding: 3px 6px;
  font-size: 8pt;
  margin: 5px;
  box-shadow: rgba(0,0,0,.2) 0 2px 5px 0!important;
  border-radius: 6px;
  text-transform: none !important;
}
.buttonFlat i {
  margin-right: 5px;
  font-size: 10pt;
}
.buttonFlat:hover{
  background-color: #ececec;
}

.buttonFlatNoText,.buttonHeaderFlat {
  background-color: white;
  box-shadow: rgba(0,0,0,.2) 0 2px 5px 0!important;
  border-radius: 6px;
  text-transform: none !important;
}

.buttonFlatNoText{
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  font-size: 8pt;
}

.buttonFlatNoText i {
  font-size: 10pt;
}

.buttonFlatNoText:hover{
  background-color: #ececec;
}

.buttonHeaderFlat{
  padding: 5px 10px;
  font-size: 9pt;
  margin: 5px 10px;
}
.buttonHeaderFlat i {
  margin-right: 5px;
  font-size: 10pt;
}
.buttonHeaderFlat:hover{
  background-color: #ececec;
}

button[disabled], html input[disabled] {
  opacity: 0.5;
}

.buttonInfoFlat, .buttonSuccessFlat {
  color: white;
  padding: 5px 10px;
  font-size: 9pt;
  margin: 5px 10px;
  box-shadow: rgba(0,0,0,.2) 0 2px 5px 0!important;
  border-radius: 6px;
  text-transform: none !important;
}

.buttonInfoFlat{
  background-color: #0068FA;
}

.buttonInfoFlat i, .buttonSuccessFlat i {
  margin-right: 5px;
  font-size: 10pt;
}
.buttonInfoFlat:hover{
  background-color: #0056ce;
}


.buttonSuccessFlat{
  background-color: #36bd6c;
}
.buttonSuccessFlat:hover{
  background-color: #35ab58;
}

.buttonWarningFlat{
  background-color: #FBC02D;
  color: #08192C;
  padding: 5px 10px;
  font-size: 9pt;
  margin: 5px 10px;
  box-shadow: rgba(0,0,0,.2) 0 2px 5px 0!important;
  border-radius: 6px;
  text-transform: none !important;
}
.buttonWarningFlat i {
  margin-right: 5px;
  font-size: 10pt;
}
.buttonWarningFlat:hover{
  background-color: #ffde99;
}

.filterSelectedBtnFlat{
  background-color: white;
  padding: 3px 6px;
  font-size: 8pt;
  margin: 5px;
  box-shadow: rgba(0,0,0,.2) 0 2px 5px 0!important;
  border-radius: 6px;
  text-transform: none !important;
}
.filterSelectedBtnFlat b{
  color: #2498e5;
}
.filterSelectedBtnFlat i {
  margin-left: 10px;
  font-size: 10pt;
}

.filterSelectedBtnFlat:hover{
  background-color: #ececec;
}

.info-tableFlat{
  margin-right: 5px;
  margin-left: 5px;
  font-size: 12px;
}

.buttonFlat-noMargin{
  margin-left: 0;
}

.progress-bar-danger{
  background-color: #ff4c6a !important;
}

.progress-bar-info{
  background-color: #a2d5ff !important;
}

.progress-meta-dsp{
  min-width: 50px;
  padding-top: 5px;
}

.progress-meta-dsp-long-bar{
  width: 100%;
  height: 7px;
  margin-bottom: -25px;

  border: solid 1px #ddd;
  background-color: #ECECEC;
}

.progress-meta-dsp-long-bar-content{
  height: 6px;
}

.progress-meta-dsp-short-line{
  margin-left: 1px;
  margin-top: 15px;
  border-right: 2px solid #3278c5;
  height: 14px;
}

/*SIDEBAR*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  background-color: #08192C;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav a {
  text-decoration: none;
  color: #818181;
  display: block;
  border-radius: 5px;
  padding: 15px 10px 10px;
  transition: 0.3s;
}

.sidenav a:hover, .offcanvas a:focus{
  color: #f1f1f1;
}

.dialog-body{
  padding: 10px
}
.commandsMenu{
  border-radius: 5px;
}
/*nasconde bordo sui bottoni focus*/
button:focus {
  outline:0 !important;
}
.otp-btn:focus{
  outline: 0 !important;
}

/*groupby colonna*/
.tr-groupby{
  height: 70px;
  font-size: 13px
}

/*nasconde scrollbar nei modali*/
.dialog-body::-webkit-scrollbar {
  display: none;
}


.ui-dialog .ui-dialog-content::-webkit-scrollbar {
  display: none;
}

.genericLabels {
  width: 170px;
  padding: 0;
}

.genericLabelsWide {
  width: 270px;
}

.inlineDiv{
  display: -webkit-box;
}

.generalSubtitle{
  min-width: 100px;
  margin: 0;
}

.prenoTitle{
  padding-bottom: 0.6em;
  font-size: 14px;
  margin-top: 3.5em;
  margin-bottom: 1.5em;
  font-weight: 600;
  border-bottom: 1px solid #e9e9e9;
}

.addAdvertiser .btn-action {
  background-color: #fff;
  border-color: #0068FA;
  color: #0068FA;
  border-width: 1px;
}

.addAdvertiser .btn-action:hover {
  background-color: #0068FA;
  color: #fff;
}

@media screen and (max-width: 992px) {

  .prenoTitle{
    font-size: 15pt !important;
  }

}

.tabsDeals{
  margin-bottom: 30px;
}

.main_content_newCampaign .prenoSection .largeRows {
  margin-bottom: 1em;
}

.main_content_newCampaign .prenoSection .modalRow {
  padding-bottom: 0;
}

@media screen and (max-width: 992px) {

  .tabsCampaign{
    top: 90px;
    background: white;
  }

  .panelCampaignDefault{
    padding: 25px 20px;
  }

}

.tableGenericHeader{
  background-color: #0068FA!important;
  color: #FFF;
}

.iconTarg{
  text-align: center;
  min-width: 25px;
  font-size: large;
  color: #2a6cab8a;
}

.genericLabels .required {
  color: #dc6f81;
  font-size: 12px;
}

a{
  cursor: pointer;
  color: #0068FA;
}

.search-table-bar{
  display: flex;
  width: 100%;
  margin: 5px 0;
}

.search-table-input{
  width: 100%;
  max-width: 400px;
}

.metrics .ui-slider {
  border-radius: 30px;
  background: #e8f1f7;
  box-shadow: 0 1px 3px #c8d6e0 inset;
}

.metrics .ui-slider .ui-slider-range {
  background: #a8deff;
  box-shadow: 0 1px 3px #1fa5ff inset;
  border-radius: 30px;
}

.metrics .disabled .ui-slider {
  background: #dfdfdf;
  box-shadow: 0 1px 3px #919191 inset;
}

.metrics .disabled .ui-slider .ui-slider-handle {
  visibility: hidden;
}

.listCreatives code[class*="language-"],
.listCreatives pre[class*="language-"] {
  background: #fbfaf9;
  border: 0;
}

.barred-text{
  text-decoration: line-through;
}

.admin-highlighter{
  border: 2px solid #00968826;
  background-color: #e4f3e5;
  margin: 0 -12px;
  padding: 20px 12px 0 12px;
  border-radius: 3px;
}

.test-highlighter{
  border: 2px solid #6616fa;
  background-color: #eae4f3;
  margin: 0 -12px;
  padding: 10px;
  border-radius: 3px;
}

.modal-under-title{
  margin: 20px 0 60px;
  padding-bottom: 30px;
}

.modalSidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: hidden;
  border-left: solid 1px #e1e6e8;
  padding: 1em;
  /*background-color: #f4f4f4;*/
  background-color: #F2F6FB;
}

.modalContainerWithoutSidebar{
  margin-top: 30px;
  overflow: auto;
  overflow-x: hidden;
  max-height: 90vh;
  min-height: 90vh;
  padding: 0 5px;
}

.modalContainerWithSidebar{
  margin-top: 15px;
  width: calc(80vw - 280px);
  padding-right: 20px;
  overflow: auto;
  overflow-x: hidden;
  max-height: 95vh;
  min-height: 90vh;
  padding-bottom: 20px;
}

.middleSidebar{
  width: calc(50vw - 210px);
}

.smallerSidebar{
  width: calc(68vw - 210px);
}
@media screen and (max-width: 992px) {
  .smallerSidebar {
    width: 100%;
  }
}

.smallerMediumSidebar{
  width: calc(785px - 210px);
}

.closeModalIcon{
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.closeModalIcon:hover{
  background-color: #ececec;
  border-radius: 5px;
}

@media screen and (max-width: 500px) {
  .closeModalIcon {
    left: unset;
    right: 0;
  }
}

.side-modal-description{
  position: absolute;
  top: 8px;
  left: 50px;
  font-size: 16px;
  color: #08192C;
  font-weight: 600;
  max-width: 80%;
}

@media screen and (max-width: 500px) {
  .side-modal-description{
    left: 10px;
    background: white;
    z-index: 1;
  }
}

.side-modal-paragraph{
  text-align: justify;
}

.side-modal-title{
  font-size: 17px;
}

.shortContainerModal{
  width: 20vw;
}

.middleContainerModal{
  width: 50vw;
}

.middleShortContainerModal{
  width: 400px;
}

.mediumContainerModal{
  max-width: 100%;
  width: 500px;
}

.mediumLargeContainerModal{
  max-width: 100%;
  width: 800px;
}

.standardLessContainerModal{
  width: 70vw;
}

.standardContainerModal{
  width: 80vw;
}

.normalLoading{
  height: 10px;
}

.span-loading-block{
  display: block;
}

.loadingBar {
  position: relative;
  overflow: hidden;
  background-color: #ccc;
}
.loadingBar::after {
  content: "";
  display: block;
  background-color: #dddfe2;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  animation: 1.5s loading-placeholder ease-in-out infinite;
}

.light-message{
  color: #999;
  padding: 7px;
  font-size: 11px;
  letter-spacing: .4px;
}

.light-id-label{
  display: inline-block;
  color: #999;
  font-size: 12px;
}

.light-message-success, .light-message-info, .light-message-grey, .light-message-warning, .light-message-error {
  padding: 7px;
}

.light-message-success, .light-message-info, .light-message-grey, .light-message-grey-light, .light-message-warning, .light-message-error {
  font-size: 11px;
  letter-spacing: .4px;
}

.light-message-success, .light-message-info, .light-message-grey, .light-message-grey-light {
  display: block;
}

.light-message-success{
  color: #009666;
  background-color: #0096661a;
  border-left: solid 2px #009666;
  width: 100%;
}

.light-message-info{
  background-color: #e2f1ff85;
  color: #4d77a1;
  border-left: solid 2px #3094d35c;
}

.light-message-grey{
  background-color: rgba(230, 230, 230, 0.52);
  color: #707070;
  border-left: solid 2px rgba(100, 100, 100, 0.36);
}

.light-message-grey-light{
  background-color: rgba(230, 230, 230, 0.22);
  color: #707070;
  border: solid 1px rgba(100, 100, 100, 0.16);
  padding: 15px;
  text-align: left;
}


.light-message-warning{
  border-left: solid 2px #ffa100;
  background-color: #ffa10014;
  color: #ff612f;
  line-height: 1.8;
  width: 100%;
}

.light-message-error{
  border-left: solid 2px #ff0000;
  background-color: rgba(241, 149, 149, 0.08);
  color: #ff2f2f;
  line-height: 1.8;
  width: 100%;
}

.alert-space-message{
  font-size: 15px;
  width: 25px;
}

.otCardPadding{
  padding: 20px;
}
.otCardShadow{
  border-radius: 2px;
  filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.05));
  background: white;
}

.otCardMaxHeightCards{
  height: 575px;
  overflow: hidden;
}
.otCardMinHeightCards{
  min-height: 515px;
}

.short-input-cerb{
  width: 80px;
}

.addon-group-cerb{
  border: solid 1px #ddd;
  font-weight: 500;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}
.flex-row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}
.flex-row.row:after,
.flex-row.row:before {
  display: flex;
}


.softButton{
  color: #0068FA;
  background-color: transparent;
  padding: 0;
  text-transform: none;
  border: none;
}
.softButton:hover{
  color: blue;
}

@keyframes loading-placeholder {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media screen and (max-width: 992px) {
  /*  SIDEBAR MODALS*/
  .modalSidebar{
    height: 50px;
    width: 0;
    bottom: 0;
    border: none;
    padding: 0;
  }

  .hideSmallmodalSidebarContent{
   display: none;
  }

  .menu-sidebar{
    position: absolute;
    left: 0;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .modalContainerWithSidebar{
    margin-right: 0;
    height: calc(100vh - 147px);
    width: calc(75vw - 40px);
  }

}

@media screen and (max-width: 500px) {
  .middleContainerModal{
    width:100%;
  }

  .shortContainerModal{
    width:100%;
  }

  .middleShortContainerModal{
    width: 100%;
  }

  .mediumContainerModal{
    width: 100%;
    max-width: unset;
  }

  .mediumLargeContainerModal{
    width: 100%;
    max-width: unset;
  }

  .standardLessContainerModal{
    width:100%;
  }
  .standardContainerModal{
    width:100%;
  }
  /*  SIDEBAR MODALS*/
  .modalContainerWithSidebar{
    width: calc(90vw);
  }
  .modalContainerWithoutSidebar{
   /* width: calc(90vw);*/
   width: 100%;
  }
}

.standard-textarea{
  width: 100%;
  max-width: 700px;
  height: 60px
}

.suggest-under-textarea{
  margin-top: -5px;
  color: #AEAEAE;
  font-weight: normal;
}

.tab-profile-container{
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .tab-profile-container{
    padding: 0;
  }
}

.box-profile{
  position: relative;
  border: solid 1px #ddd;
  padding: 20px 20px 0;
  background-color: #fafafa;
  max-width: 700px;
}

.footer-settings-profile-container{
  margin: 0 -20px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.footer-settings-profile{
  width: 100%;
  border-top: solid 1px #ddd;
  padding: 10px 7px;
  border-radius: 0;
  background-color: #f7f7f7;
  text-align: right;
  position: -webkit-sticky;
  min-height: 55px;
}

.width100-perc{
  width: 100%;
}

.copy-general-button {
  background: #F0F0F0;
  color: #0087F6;
  border: 2px solid #0087F6;
  border-radius: 5px;
  font-size: 8pt;
}
.copy-general-button:hover {
  background: #e4e4e4;
}

.clickable-hand{
  cursor: pointer;
}

.disabledAll{
  opacity: 0.6;
  pointer-events:none;
}

.disabledAllLight{
  opacity: 0.9;
  pointer-events:none;
}

.no-margin{
  margin: 0!important;
}

.default-list-element{
  padding: 5px;
  color: #00b3f1;
  font-size: 13px;
  margin-left: -15px;
}

.pageSubtitle{
  font-size: 16px;
  font-weight: bold;
}

.validation-success{
  color: #37bd6c;
  font-size: xx-small;
}

.validation-alert{
  color: #ff4c6a;
  font-size: xx-small;
}


/***************Primeng**************/

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #848484;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #545454;
}

table {
  border-collapse: separate;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  border: none;
  padding: .571rem .857rem;
  /* border: 1px solid rgba(200, 200, 200, 0.25); */
}

.p-datatable .p-datatable-thead > tr > .cell-report-table,
.p-datatable .p-datatable-tbody > tr > .cell-report-table {
  border: 1px solid rgba(200, 200, 200, 0.25);
}

.p-datatable .p-datatable-thead > tr > .header-table-report {
  background-color:#0068FA;
  color: white;
  font-size: 13px;
}

.p-datatable .p-datatable-thead > tr > .cell-report-table > .p-element > .p-sortable-column-icon {
  color: white;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #08192C!important;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: #00b3f1!important;
  color: #ffffff;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #f5f5f5;
}

body table tbody.p-element {
  font-size: 13px;
}

body table tbody.p-element.p-datatable-tbody > tr:nth-child(even) {
 background-color: #f9f9f9;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #0068FA!important;
  color: #ffffff!important;
}

body .p-treetable .p-treetable-thead > tr > th {
  color: #FFF;
  background-color: #3e96ec;
  padding: .571rem .857rem;
}

.p-treetable .p-treetable-header {
  background: #fff;
  border: none;
  padding: 0.25rem 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > th,
.p-treetable .p-treetable-tbody > tr > td {
  border: none;
  text-align: left;
  padding: .571rem .857rem;
}

.p-slider.p-slider-horizontal {
  height: 0.486rem;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}

.p-selectbutton .p-button {
  background: white;
  color: #08192C;
}

.p-togglebutton.p-button {
  background: #ffffff;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #0068FA;
  border-color: #0068FA;
  color: white;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #0068FA;
  background: #0068FA;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #0068FA;
  background: #0068FA;
}

.p-slider .p-slider-range {
  background: #0068FA;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  border-color: #0068FA;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #0068FA;
  border-color: #0068FA;
  color: white;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #0068FA;
  background: #0068FA;
}
.p-selectbutton .p-button.p-highlight {
  background: #0068FA;
  border-color: #0068FA;
  color: white;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #0068FA;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background: #0068FA;
}
.p-button {
  background: #0068FA;
  border-color: #0068FA;
  color: #fff;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: .429rem 0;
}
.p-calendar-w-btn .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #0068FA;
}
.p-inputtext {
  border-color: #e8e6e6;
}
.p-multiselect {
  border-color: #e8e6e6;
}
.p-dropdown {
  border-color: #e8e6e6;
}

.p-checkbox-label {
  margin-bottom: 0;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  padding: .2145rem .429rem;
  background: #007ad9;
  color: #fff;
  border-radius: 3px;
}

/* targeting */

.target-text-area{
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 5px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #dddddd;
  padding: 5px 15px;
}

.list-textarea-sty{
  font-weight: 400;
  font-size: smaller;
}

.copy-button{
  padding: 4px;
  font-size: 8pt;
}

@media screen and (max-width: 450px) {
  .target-text-area{
    width: 100%;
    margin: 0;
    border: none;
  }
}
